import Q from "q";
// 支付组件
import PaymentMethodSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000/index.vue";

// 出差单详情
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import moment from "moment";

export default {
  components: {
    PaymentMethodSelector,
  },
  data() {
    return {
      loading: false,
			// 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
			orderType: '',
			// 1是创建订单页，2是支付页，3是改签页
			pageType: '',
      detail: {},
      params: {
        evectionNo: "",
        userIds: "",
      },
      detectionDetail: [],
      zhif_rule: "",
      value: "",
      successVisible: false,
      showDetail: false,

      errorDialog: false,
      errorText: '',

      flightOrderList: [],
    };
  },
  computed: {},
  methods: {
    req_detail(params) {
      consumer_journey_getEvectionDetail(params).then((res) => {
        let flightOrderList = []
      if (this.pageType === 3) {
        // 改签
        if (this.orderType === 1) {
          const datas = res.datas;
          const flightDetail = datas.flightDetail;
          let detail = {
            reason: datas.reason,
            travellerList: flightDetail.orderList[0].passengerList.map((item) => {
              return {staffName: item.psgName};
            }),
            linkman: datas.linkman,
            linkmanPhone: datas.linkmanPhone,
            feeAffiliationName: datas.feeAffiliationName,
            businessContentVo: datas.businessContentVo,
            flightDetail: flightDetail,
            serviceFee: datas.serviceFee,
            sum: flightDetail.orderList[0].chaAmount,
            evectionType: datas.evectionType,
          };
          this.detail = detail;
          flightDetail.orderList.forEach(value => {
            flightOrderList.push(value.orderNo)
          })
          this.flightOrderList = flightOrderList
        } else if (this.orderType === 2) {
          const datas = res.datas.changeDetailResponse;
          let train = {
            trainNo: datas.changeToStationCode,
            seatName: datas.changeSeatName,
            fromStationName: datas.changeFromStationName,
            spanTime: '',
            startTime: datas.changeStartTime,
            startDate: datas.changeStartDate,
            arriveDate: datas.changeArriveDate,
            toStationName: datas.changeToStationName,
            arriveTime: datas.changeArriveTime,
            seatPrice: datas.changeSeatPrice,
            evectionType: datas.evectionType,
            orderNo: datas.changeNo,
            headCounts: datas.passengers.length,
            passengerNames: datas.passengerNames,
          };
          let detail = {
            reason: res.datas.reason,
            travellerList: datas.passengers.map((item) => {return {staffName: item.passengerName};}),
            linkman: res.datas.linkman,
            linkmanPhone: res.datas.linkmanPhone,
            feeAffiliationName: res.datas.feeAffiliationName,
            sum: datas.changeTotalAmount,
            businessContentVo: res.datas.businessContentVo,
            ticketBookingPageDetailsResponseList: [train],
            evectionType: datas.evectionType
          };
          this.detail = detail;
        }
      } else {
        this.detail = res.datas;
        if (this.detail.flightDetail && this.detail.flightDetail.orderList && this.detail.flightDetail.orderList.length > 0) {
          this.detail.flightDetail.orderList.forEach(value => {
            flightOrderList.push(value.orderNo)
          })
        }
        this.flightOrderList = flightOrderList
      }

        this.payment_init();
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },

    payment_init() {
      const __this = this;
      const init_000 = function() {
        const query = __this.$route.query;
        const { businessContentVo, reason, sum, evectionType } = __this.detail;
        __this.$refs.aPaymentMethodSelector_000.init({


          btn_pay: {
            text: `确认并支付`,
          },
          get_params () {
            const p ={
              evectionNo: __this.orderType ? '' : query.evectionNo,
              orderNo: __this.orderType ? query.orderNo : '', //订单编号
              businessContent: businessContentVo, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里
              subject: evectionType === 1 ? '因私出行' : reason, //
              orderAmount: sum,//  订单金额，用于计算可以用哪些支付方式支付
              count_down: {
                enable: true, //是否启用倒计时，默认值为false
                pay_remaining_seconds: 30 * 60 * 1, //支付剩余时间，以秒为单位
              },
            };
            return Q.resolve(p);
          },
          // situationParameter: {
          //   "000": {
          //     // companyId: ``,
          //     // clubId: ``,//中间社id
          //     orderNo: __this.orderType ? query.orderNo : query.evectionNo, //订单编号
          //     businessContent: businessContentVo, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里
          //     // isUseCredit: true,//是否允许授信支付
          //     subject: evectionType === 1 ? '因私出行' : reason, //
          //     orderAmount: sum,//  订单金额，用于计算可以用哪些支付方式支付
          //     count_down: {
          //       enable: true, //是否启用倒计时，默认值为false
          //       pay_remaining_seconds: 60 * 60 * 1, //支付剩余时间，以秒为单位
          //     },
          //   },
          // },

          //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
          before_pay_event_handler() {
            // alert(`不能够支付！原因：XXX`);
            //return Q.reject();

            if (__this.flightOrderList.length > 0 && __this.pageType !== 3) {
              // 如果有机票订单，则验价
              return consumer_admin_flight_checkFlightPay({orderNo: __this.flightOrderList}).then(res => {
                return Q.resolve();
              }, error => {
                __this.errorText = error.data.msg
                __this.errorDialog = true
                //__this.init()
                return Q.reject()
              })
            } else {
              return Q.resolve();
            }
          },
          //支付成功事件处理方法
          pay_successful_event_handler() {
            // __this.test();
            __this.successVisible = true;
          },
          //支付失败事件处理方法
          pay_failed_event_handler() {
            console.log(`pay_failed_event_handler`);
          },
        });
      };

      const init_100 = function() {
        __this.$refs.aPaymentMethodSelector_100.init({
          getSituationParameter() {
            const o = {
              "100": {
                companyId: 151,
                refundType: 1, //还款类型：1.一键还款，2.主账单还款，3.子账单还款
                orderAmount: 0.01, //订单金额，用于计算可以用哪些支付方式支付
                billIds: `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
              },
            };
            return o;
          },

          btn_pay: {
            text: `支付（自定义文本）`,
          },

          //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
          before_pay_event_handler() {
            // alert(`不能够支付！原因：XXX`);
            // return Q.reject();

            return Q.resolve();
          },

          //支付成功事件处理方法
          pay_successful_event_handler() {
            console.log(`pay_successful_event_handler`);
          },
          //支付失败事件处理方法
          pay_failed_event_handler() {
            console.log(`pay_failed_event_handler`);
          },
        });
      };

      const init_200 = function() {
        __this.$refs.aPaymentMethodSelector_200.init({
          situationParameter: {
            "200": {
              companyId: 151,
              orderNo: `D568298692091`, //订单编号
              orderAmount: 1000, //订单金额，用于计算可以用哪些支付方式支付
            },
          },

          btn_pay: {
            text: `支付（自定义文本）`,
          },

          //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
          before_pay_event_handler() {
            // alert(`不能够支付！原因：XXX`);
            // return Q.reject();

            return Q.resolve();
          },
          //支付成功事件处理方法
          pay_successful_event_handler() {
            console.log(`pay_successful_event_handler`);
          },
          //支付失败事件处理方法
          pay_failed_event_handler() {
            console.log(`pay_failed_event_handler`);
          },
        });
      };

      init_000();
    },
    toInit() {
      this.errorDialog = false
      this.init()
    },
    init() {
			const query = this.$route.query;

			this.orderType = Number(query.orderType) || '';
			this.pageType = Number(query.pageType) || 2;
      this.loading = true;
      if (this.orderType) {
        const pageType = this.pageType;
        if (pageType === 3) {
          this.req_detail({ orderNo: query.orderNo, pageType: this.pageType, personType: 2});
        } else if (pageType === 2) {
          this.req_detail({ orderNo: query.orderNo, pageType: this.pageType, personType: 2});
        }
      } else {
        this.req_detail({ evectionNo: query.evectionNo, pageType: this.pageType, personType: 2});
      }
    },
    to_detail() {
      this.successVisible = false
      const type = Number(this.orderType);
      const pageType = this.pageType;
      console.log(type, 'dgd ')
      if (type) {

        const orderNo = this.$route.query.orderNo;
        if (type === 1) {
          if (pageType === 2) {
            this.$router.push({
              name: "admin-my-order-flight-detail",
              query: {
                orderNo,
              },
            });
          } else if (pageType === 3) {
            this.$router.push({
              name: "admin-my-order-flight-changeList",
              query: {
                orderNo,
              },
            });
          }
        } else if (type === 2) {
          if (pageType === 2) {
            this.$router.push({
              name: "admin-my-order-train-detail",
              query: {
                orderNo,
              },
            });
          } else if (pageType === 3) {
            this.$router.push({
              name: "admin-my-order-train-change-detail",
              query: {
                orderNo,
              },
            });
          }
        } else if (type === 3) {
          this.$router.push({
            name: "admin-hotel-order-detail",
            query: {
              orderNo,
            },
          });
        }
      } else {
        this.$router.push({
          name: "admin-my-order-flight-orderList",
        });
      }
    },
    format_coseList(val) {
      return val.split("|").reduce((acc, item) => (acc += Number(item)), 0);
    },

    to_order_detail () {
      this.showDetail = !this.showDetail;
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {},
  filters: {
    format_train_departDate(val) {
      return moment(val).format("YYYY年MM月DD日");
    },
    format_segmentType(val) {
      switch (val) {
        case 1:
          return "去";
          break;

        case 2:
          return "返";
          break;
      }
    },
    format_duration(val) {
      return val.replace("h", "小时").replace("m", "分钟");
    },
  },
};
