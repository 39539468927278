var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"travel_payment"},[_c('div',{staticClass:"page_title flex flex-align-end flex-pack-justify"},[_c('div',{staticClass:"left flex flex-align-end"},[_c('div',{staticClass:"title"},[_vm._v("铨成收银台")]),_c('div',{staticClass:"order_amount"},[_c('span',{staticClass:"label"},[_vm._v("订单总价：")]),_c('span',{staticClass:"value"},[_vm._v("￥"+_vm._s(_vm.detail.sum))])])]),_c('div',{staticClass:"right"},[(!_vm.showDetail)?_c('el-button',{staticClass:"details_btns click_active",attrs:{"type":"text"},on:{"click":_vm.to_order_detail}},[_vm._v("订单详情")]):_vm._e(),(_vm.showDetail)?_c('el-button',{staticClass:"details_btns click_active",attrs:{"type":"text"},on:{"click":_vm.to_order_detail}},[_vm._v("产品详情")]):_vm._e()],1)]),(!_vm.showDetail)?_c('div',{staticClass:"card_box detail_amount flex"},[_c('div',{staticClass:"order_detail flex1"},[_c('div',{staticClass:"business_list"},[(
            _vm.detail.flightDetail &&
            _vm.detail.flightDetail.orderList &&
            _vm.detail.flightDetail.orderList.length
          )?_c('ul',{staticClass:"business_item flight_list"},_vm._l((_vm.detail.flightDetail.orderList),function(flight,fIndex){return _c('li',{key:fIndex,staticClass:"flight_item flex flex-align-center flex-pack-justify"},[_c('div',{staticClass:"left flex1 flex"},[_c('div',{staticClass:"flight_airline flex flex-align-center flex-v"},[_c('div',{staticClass:"airline"},[_c('el-image',{attrs:{"src":flight.segmentList[0].airCompanyLogoImg}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(flight.segmentList[0].airCompanyName))]),_c('span',{staticClass:"go_back"},[_vm._v(_vm._s(flight.segmentList[0].segmentType == 1 ? "往" : flight.segmentList[0].segmentType == 2 ? "返" : "--"))])],1),_c('span',{staticClass:"flight_number"},[_vm._v(_vm._s(flight.segmentList[0].flightNo))]),_c('span',{staticClass:"airplane_model"},[_vm._v(_vm._s(flight.segmentList[0].planeType))])]),_c('div',{staticClass:"span_time flex1 flex flex-align-center flex-pack-around"},[_c('div',{staticClass:"station flex flex-v"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$moment(flight.segmentList[0].depDate).format( "MM月DD日" ))+" "+_vm._s(flight.segmentList[0].depTime))]),_c('span',{staticClass:"airport"},[_vm._v(_vm._s(flight.segmentList[0].depAirportName))])]),(
                    flight.segmentList[0].stopData &&
                    flight.segmentList[0].stopData.stopInfos &&
                    flight.segmentList[0].stopData.stopInfos.length
                  )?_c('div',{staticClass:"transit"},[_c('span',[_vm._v("经停："+_vm._s(flight.segmentList[0].stopData.stopInfos .map(function (item) { return item.cityName; }) .join(",")))])]):_vm._e(),_c('div',{staticClass:"station flex flex-v"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$moment(flight.segmentList[0].arrDate).format( "MM月DD日" ))+" "+_vm._s(flight.segmentList[0].arrTime))]),_c('span',{staticClass:"airport"},[_vm._v(_vm._s(flight.segmentList[0].arrAirportName))])])])]),_c('div',{staticClass:"right"},[(flight.passengerList)?_c('span',[_vm._v(_vm._s(flight.passengerList.map(function (item) { return item.psgName; }).join("/")))]):_vm._e()])])}),0):_vm._e(),(
            _vm.detail.todHotelOrderInterimList &&
            _vm.detail.todHotelOrderInterimList.length
          )?_c('ul',{staticClass:"business_item hotel_list"},_vm._l((_vm.detail.todHotelOrderInterimList),function(hotel,hIndex){return _c('li',{key:hIndex,staticClass:"hotel_item flex flex-pack-justify"},[_c('div',{staticClass:"left flex"},[_c('el-image',{attrs:{"src":hotel.hotelPicUrl}}),_c('div',{staticClass:"hotel_info"},[_c('h4',{staticClass:"hotel_name"},[_vm._v(" "+_vm._s(hotel.hotelName)+"（"+_vm._s(hotel.cityName)+_vm._s(hotel.hotelAddr)+"） ")]),_c('div',{staticClass:"room_type"},[_vm._v(_vm._s(hotel.hotelRoomTypeName))])])],1),_c('div',{staticClass:"right flex flex-v"},[(hotel.orderPersonList)?_c('span',[_vm._v(_vm._s(JSON.parse(hotel.orderPersonList) .map(function (item) { return item.lastName + item.firstName; }) .join("/")))]):_vm._e(),_c('span',[_vm._v(_vm._s(hotel.checkInDate)+"至"+_vm._s(hotel.checkOutDate))]),_c('span',[_vm._v(_vm._s(hotel.roomNum)+"间 共"+_vm._s(hotel.stayNights)+"晚")])])])}),0):_vm._e(),(
            _vm.detail.ticketBookingPageDetailsResponseList &&
            _vm.detail.ticketBookingPageDetailsResponseList.length
          )?_c('ul',{staticClass:"business_item train_list"},_vm._l((_vm.detail.ticketBookingPageDetailsResponseList),function(train,tIndex){return _c('li',{key:tIndex,staticClass:"train_item flex flex-align-center flex-pack-justify"},[_c('div',{staticClass:"trainNo_seat"},[_c('div',{staticClass:"trainNo"},[_vm._v(_vm._s(train.trainNo))]),_c('div',{staticClass:"seat"},[_vm._v(_vm._s(train.seatName))])]),_c('div',{staticClass:"station_time"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$moment(train.startDate).format("MM月DD日"))+" "+_vm._s(train.startTime)+" ")]),_c('div',[_c('span',{staticClass:"starting"},[_vm._v("始")]),_c('span',{staticClass:"sation"},[_vm._v(_vm._s(train.fromStationName))])])]),_c('div',{staticClass:"station_time"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$moment(train.arriveDate).format("MM月DD日"))+" "+_vm._s(train.arriveTime)+" ")]),_c('div',[_c('span',{staticClass:"destination"},[_vm._v("止")]),_c('span',{staticClass:"sation"},[_vm._v(_vm._s(train.toStationName))])])]),_c('div',{staticClass:"passenger"},[_vm._v(_vm._s(train.passengerNames))])])}),0):_vm._e()])]),_c('div',{staticClass:"amount_detail"},[_c('ul',{staticClass:"amount_list"},[(
            _vm.detail.flightDetail &&
            _vm.detail.flightDetail.orderList &&
            _vm.detail.flightDetail.orderList.length
          )?_vm._l((_vm.detail.flightDetail.orderList),function(flight,fIndex){return _c('li',{key:fIndex + 'flight',staticClass:"amount_item"},[_c('div',{staticClass:"title"},[_vm._v(" [机票]"+_vm._s(flight.segmentList[0].depCityName)+"-"+_vm._s(flight.segmentList[0].arrCityName)+" "+_vm._s(flight.segmentList[0].cabinName)+" ")]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"label"},[_vm._v("成人票价")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"amount"},[_vm._v(" ￥"+_vm._s(flight.segmentList[0].settlement)+" x "+_vm._s(flight.headCounts)+"人 ")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"label"},[_vm._v("机建/燃油")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"amount"},[_vm._v(" ￥"+_vm._s(flight.segmentList[0].airportTax + flight.segmentList[0].fuelTax)+" x "+_vm._s(flight.headCounts)+"人 ")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"label"},[_vm._v("服务费")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"amount"},[_vm._v(" ￥"+_vm._s((flight.serviceFee || 0) / flight.headCounts)+" x "+_vm._s(flight.headCounts)+"人 ")])])])}):_vm._e(),(
            _vm.detail.todHotelOrderInterimList &&
            _vm.detail.todHotelOrderInterimList.length
          )?_vm._l((_vm.detail.todHotelOrderInterimList),function(hotel,hIndex){return _c('li',{key:hIndex + 'hotel',staticClass:"amount_item"},[_c('div',{staticClass:"title"},[_vm._v(" [酒店]"+_vm._s(hotel.hotelName)+_vm._s(hotel.hotelRoomTypeName)+" ")]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"label"},[_vm._v("房间")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"amount"},[_vm._v(" ￥"+_vm._s(hotel.price)+" x "+_vm._s(JSON.parse(hotel.orderPersonList).length)+"间 x "+_vm._s(hotel.stayNights)+"晚 ")])])])}):_vm._e(),(
            _vm.detail.ticketBookingPageDetailsResponseList &&
            _vm.detail.ticketBookingPageDetailsResponseList.length
          )?_vm._l((_vm.detail.ticketBookingPageDetailsResponseList),function(train,tIndex){return _c('li',{key:tIndex + 'train',staticClass:"amount_item"},[_c('div',{staticClass:"title"},[_vm._v(" [火车票]"+_vm._s(train.trainNo)+" "+_vm._s(train.seatName)+" ")]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"label"},[_vm._v("成人票价")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"amount"},[_vm._v(" ￥"+_vm._s(train.seatPrice)+" x "+_vm._s(train.headCounts)+"人 ")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"label"},[_vm._v("服务费")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"amount"},[_vm._v(" ￥"+_vm._s((train.serviceChargeAmount || 0) / train.headCounts)+" x "+_vm._s(train.headCounts)+"人 ")])])])}):_vm._e()],2)])]):_vm._e(),(_vm.showDetail)?_c('div',{staticClass:"card_box table_box"},[_vm._m(0),(
        _vm.detail.flightDetail &&
        _vm.detail.flightDetail.orderList &&
        _vm.detail.flightDetail.orderList.length
      )?_vm._l((_vm.detail.flightDetail.orderList),function(flight,fIndex){return _c('ul',{key:fIndex + 'flight',staticClass:"table_row"},[_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(flight.segmentList[0].depDate)+" "+_vm._s(flight.segmentList[0].depTime))]),_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(flight.orderNo))]),_c('li',{staticClass:"table_row_column"},[_vm._v("机票")]),_c('li',{staticClass:"table_row_column"},[_vm._v(" "+_vm._s(flight.segmentList[0].depCityName)+"-"+_vm._s(flight.segmentList[0].arrCityName)+" ")]),_c('li',{staticClass:"table_row_column"},[(flight.passengerList && flight.passengerList.length)?[_vm._v(_vm._s(flight.passengerList.map(function (item) { return item.psgName; }).join("/")))]:[_vm._v("--")]],2),_c('li',{staticClass:"table_row_column"},[_c('span',{staticClass:"price"},[_vm._v("（￥"+_vm._s((flight.segmentList[0].settlement || 0) + (flight.segmentList[0].airportTax || 0) + (flight.segmentList[0].fuelTax || 0) + (Number(flight.serviceFee) || 0) / flight.headCounts)+"*"+_vm._s(flight.headCounts)+"）")]),_c('span',{staticClass:"amount"},[_vm._v("￥"+_vm._s(Number(flight.supTotalPay) + Number(flight.serviceFee)))])])])}):_vm._e(),(
        _vm.detail.todHotelOrderInterimList &&
        _vm.detail.todHotelOrderInterimList.length
      )?_vm._l((_vm.detail.todHotelOrderInterimList),function(hotel,hIndex){return _c('ul',{key:hIndex + 'hotel',staticClass:"table_row"},[_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(_vm.$moment(hotel.checkInDate + ' ' + hotel.latestArrivalTime).format('YYYY-MM-DD HH:mm')))]),_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(hotel.orderNo))]),_c('li',{staticClass:"table_row_column"},[_vm._v("酒店")]),_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(hotel.cityName))]),_c('li',{staticClass:"table_row_column"},[(hotel.orderPersonList)?[_vm._v(_vm._s(JSON.parse(hotel.orderPersonList) .map(function (item) { return item.lastName + item.firstName; }) .join("/")))]:[_vm._v("--")]],2),_c('li',{staticClass:"table_row_column"},[_c('span',{staticClass:"price"},[_vm._v("（￥"+_vm._s(hotel.price)+"*"+_vm._s(hotel.roomNum)+"*"+_vm._s(hotel.stayNights)+"）")]),_c('span',{staticClass:"amount"},[_vm._v("￥"+_vm._s(hotel.orderAmount))])])])}):_vm._e(),(
        _vm.detail.ticketBookingPageDetailsResponseList &&
        _vm.detail.ticketBookingPageDetailsResponseList.length
      )?_vm._l((_vm.detail.ticketBookingPageDetailsResponseList),function(train,tIndex){return _c('ul',{key:tIndex + 'train',staticClass:"table_row"},[_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(train.startDate)+" "+_vm._s(train.startTime))]),_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(train.orderNo))]),_c('li',{staticClass:"table_row_column"},[_vm._v("火车票")]),_c('li',{staticClass:"table_row_column"},[_vm._v(" "+_vm._s(train.fromStationName)+"-"+_vm._s(train.toStationName)+" ")]),_c('li',{staticClass:"table_row_column"},[_vm._v(_vm._s(train.passengerNames))]),_c('li',{staticClass:"table_row_column"},[_c('span',{staticClass:"price"},[_vm._v("（￥"+_vm._s(Number(train.seatPrice) + Number(train.serviceChargeAmount) / train.headCounts)+"*"+_vm._s(train.headCounts)+"）")]),_c('span',{staticClass:"amount"},[(_vm.$route.query.pageType == 3)?[_vm._v("￥"+_vm._s(train.seatPrice))]:[_vm._v("￥"+_vm._s(train.buyerPaymentTotalAmount))]],2)])])}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"card_box payment_box"},[_c('PaymentMethodSelector',{ref:"aPaymentMethodSelector_000",staticClass:"payment",attrs:{"situation":"000"}})],1),_c('el-dialog',{staticClass:"haolv_dialog success_dialog",attrs:{"visible":_vm.successVisible,"close-on-click-modal":false,"show-close":false,"before-close":function () {
        _vm.successVisible = false;
      }}},[_c('div',{staticClass:"flex flex-align-end flex-pack-justify",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"title"},[_vm._v("支付结果")]),_c('i',{staticClass:"el-icon-close click_active cursor",on:{"click":_vm.to_detail}})]),_c('div',{staticClass:"success_content"},[_c('p',[_c('i',{staticClass:"haolv-iconfont haolv-iconsuccess"})]),_c('p',{staticClass:"success_text"},[_vm._v("恭喜您，订单支付成功！")])]),_c('div',{staticClass:"el_footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.to_detail}},[_vm._v("查看订单详情")])],1)]),_c('el-dialog',{attrs:{"title":"温馨提示","visible":_vm.errorDialog,"width":"400px","close-on-click-modal":false,"show-close":false},on:{"update:visible":function($event){_vm.errorDialog=$event}}},[_c('span',[_vm._v(_vm._s(_vm.errorText))]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toInit}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"table_title"},[_c('li',{staticClass:"title_item"},[_vm._v("出行日期")]),_c('li',{staticClass:"title_item"},[_vm._v("订单号")]),_c('li',{staticClass:"title_item"},[_vm._v("商品类型")]),_c('li',{staticClass:"title_item"},[_vm._v("行程")]),_c('li',{staticClass:"title_item"},[_vm._v("出行人")]),_c('li',{staticClass:"title_item"},[_vm._v("待支付金额")])])}]

export { render, staticRenderFns }